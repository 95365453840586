import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import GlobalStyle from './styles/global';

import Home from './pages/Home';
import Demo from './pages/Demo';
import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Challenge from './pages/User/Challenge';
import Secret from './pages/User/Secret';
import Complete from './pages/User/SecretComplete';
import Recovery from './pages/User/Recovery';
import Step5 from './pages/User/Step5';
import Step6 from './pages/User/Step6';
import Step7 from './pages/User/Step7';
import Q1 from './pages/Question/Q1';
import Q2 from './pages/Question/Q2';
import Q3 from './pages/Question/Q3';
import A1 from './pages/Answer/A1';
import A2 from './pages/Answer/A2';
import A3 from './pages/Answer/A3';
import A4 from './pages/Answer/A4';
import Tutorial from './pages/Tutorial';
import Wrong from './pages/User/WrongInfo';

const App = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
      <GlobalStyle />
      <Header />
      <Switch>
        <Main>
          <Route exact path="/" component={Home} />
          <Route exact path="/demo" component={Demo} />
          <Route path="/demo/step-1" component={Step1} />
          <Route path="/demo/step-2" component={Step2} />
          <Route path="/demo/start" component={Step3} />
          <Route path="/demo/user/challenge" component={Challenge} />
          <Route path="/demo/user/secret" component={Secret} />
          <Route path="/demo/user/complete" component={Complete} />
          <Route path="/demo/user/recovery" component={Recovery} />
          <Route path="/demo/step-5" component={Step5} />
          <Route path="/demo/step-6" component={Step6} />
          <Route path="/demo/step-7" component={Step7} />
          <Route path="/demo/question-1" component={Q1} />
          <Route path="/demo/question-2" component={Q2} />
          <Route path="/demo/question-3" component={Q3} />
          <Route path="/demo/answer-1" component={A1} />
          <Route path="/demo/answer-2" component={A2} />
          <Route path="/demo/answer-3" component={A3} />
          <Route path="/demo/answer-4" component={A4} />
          <Route path="/demo/tutorial" component={Tutorial} />
          <Route path="/demo/wrong" component={Wrong} />
        </Main>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
