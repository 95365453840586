import { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, SubTitle, Title, Text as BaseText } from '../../components/Toolkit';

const Step7 = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.user);

  const [secretInfo, setSecretInfo] = useState('...');
  const [alert, setAlert] = useState('');
  const [countsOfNum, setCountsOfNum] = useState(0);
  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    setSecretInfo(user.secret || '...');
  }, [user]);

  useEffect(() => {
    let counts = 0;
    const interval = setInterval(() => {
      setCountsOfNum((count) => count + 1);
      if (counts === 0) setAlert('awaiting1');
      else if (counts === 1) setAlert('awaiting2');
      else if (counts === 2) setAlert('awaiting3');
      else clearInterval(interval);
      counts += 1;
    }, 1000);
  }, []);

  useEffect(() => {
    const _answers = localStorage.getItem('answer') || '[{"1": "test"}]';
    const answers = JSON.parse(_answers);
    const temp: string[] = [];
    answers.map((answer: ChallengeProp) => {
      temp.push(Object.values(answer)[0]);
    });
    const matches: any[] = [];
    user.words.map((challenge) => {
      const res = temp.find((element: any) => element === Object.values(challenge)[0]);
      matches.push(res);
    });
    if (matches.includes(undefined)) setIsValidated(false);
    else setIsValidated(true);
  }, [user]);

  const okHandler = useCallback(() => {
    history.push('/demo/step-2');
  }, [history]);

  const endHandler = useCallback(() => {
    history.push('/');
  }, [history]);

  const tryHandler = useCallback(() => {
    history.push('/demo/user/recovery');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('user page')}
        </Title>
        {countsOfNum < 4 ? (
          <SubTitle textAlign="left">{t(alert)}</SubTitle>
        ) : (
          <div>
            {isValidated ? (
              <>
                <SubTitle mb="25px" textAlign="left">
                  {t('challenges completed')}
                </SubTitle>
                <SubTitle textAlign="left">
                  <Trans components={{ br: <br /> }}>recovery validated</Trans>
                </SubTitle>

                <Text textAlign="left">{secretInfo}</Text>
              </>
            ) : (
              <>
                <SubTitle textAlign="left" mb="25px">
                  <Trans components={{ br: <br /> }}>recovery denied</Trans>
                </SubTitle>
                <Button type="submit" onClick={tryHandler} style={{ marginBottom: 60 }}>
                  {t('try again button')}
                </Button>
              </>
            )}
            <ButtonsWrapper>
              <Button type="submit" onClick={okHandler}>
                {t('test other button')}
              </Button>
              <Button type="submit" onClick={endHandler}>
                {t('end button')}
              </Button>
            </ButtonsWrapper>
          </div>
        )}
      </Container>
    </DemoWrapper>
  );
};

export default Step7;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Button = styled(BaseButton)`
  margin-bottom: 25px;
`;

const Text = styled(BaseText)`
  margin: 25px 0;
  padding: 10px;
  font-size: 20px;
  line-height: 1.3;
  border-radius: 10px;
  border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
`;
