import styled from 'styled-components';

const Button = styled.button`
  min-width: 150px;
  padding: 12px 25px;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: 20px;
  box-sizing: border-box;
  border: none;
  font-size: 18px;
  line-height: 1.3;
  text-align: center;
  cursor: pointer;

  &:active {
    border: none;
  }

  &:hover:not(:active):not(:disabled) {
    background: ${({ theme }) => theme.colors.hover};
    border: none;
  }

  &:disabled {
    border: none;
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const StyledButton = styled(Button)`
  padding: 5px 20px;
  background: ${({ theme }) => theme.colors.primary};
  border: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  border-radius: 17px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textSecondary};

  &:active {
    border: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  }

  &:hover:not(:active):not(:disabled) {
    background: ${({ theme }) => theme.colors.textSecondary};
    color: ${({ theme }) => theme.colors.primary};
    border: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 40px;
    padding: 8px 38px;
    font-size: 24px;
    line-height: 28px;
    border: ${({ theme }) => `7px solid ${theme.colors.secondary}`};

    &:active {
      border: ${({ theme }) => `7px solid ${theme.colors.secondary}`};
    }

    &:hover:not(:active):not(:disabled) {
      border: ${({ theme }) => `7px solid ${theme.colors.secondary}`};
    }
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin: 0 132px;
    padding: 15px 105px;
    font-size: 40px;
    line-height: 46px;
  }
`;

export default Button;
