import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '../Toolkit';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Content>{t('collect1')}</Content>
      <br />
      <Content>{t('collect2')}</Content>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  padding: 35px 20px;
  background: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 84px 25px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 0 400px 70px;
  }
`;

const Content = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textSecondary};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
    line-height: 18px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 24px;
    line-height: 28px;
  }
`;
