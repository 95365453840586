import styled from 'styled-components';

interface InputProps {
  width?: number;
  height?: number;
  bg?: string;
  radius?: number;
}

const Input = styled.input<InputProps>`
  width: ${({ width }) => (width ? `${width}px` : 'calc(100% - 24px)')};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  padding: 10px;
  background: ${({ bg }) => (bg ? bg : 'transparent')};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : '10px')};
  border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
  outline: 0;
  font-size: 20px;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.textPrimary};

  &:active {
    background: ${({ bg }) => (bg ? bg : 'transparent')};
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  &:hover {
    background: ${({ bg }) => (bg ? bg : 'transparent')};
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  &:disabled {
    background: ${({ bg }) => (bg ? bg : 'transparent')};
    color: ${({ theme }) => theme.colors.textPrimary};
    cursor: not-allowed;
  }

  &:focus:not(:disabled) {
    background: ${({ bg }) => (bg ? bg : 'transparent')};
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

export default Input;
