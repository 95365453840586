import { FC, useState, useCallback } from 'react';
import styled from 'styled-components';
import Dropdown from './Dropdown';
import { SelectProps } from './types';
import { Flex, Image, ArrowDownIcon } from '../Toolkit';

const SelectWrapper = styled.div`
  position: relative;
`;

const Wrap = styled(Flex)`
  cursor: pointer;
`;

const Select: FC<SelectProps> = ({ lang, flag, options, isBottom, cbHandler, ...props }) => {
  const [isDropdown, setIsDropdown] = useState(false);

  const changeHandler = useCallback(() => {
    setIsDropdown(true);
  }, []);

  const closeHandler = useCallback(
    (idx: number) => {
      setIsDropdown(false);
      cbHandler(idx);
    },
    [cbHandler],
  );

  return (
    <SelectWrapper {...props}>
      <Wrap onClick={changeHandler}>
        <FlagImage src={flag} alt="Flag" />
        <Text>{lang}</Text>
        <ArrowDownIcon />
      </Wrap>
      {isDropdown && <Dropdown options={options} isBottom={isBottom} closeHandler={closeHandler} />}
    </SelectWrapper>
  );
};

export default Select;

const Text = styled.div`
  margin: 0 10px;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 32px;
    line-height: 37px;
  }
`;

const FlagImage = styled(Image)`
  width: 20px;
  height: 20px;

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 32px;
    height: 32px;
  }
`;
