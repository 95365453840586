import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Image, Text } from '../../components/Toolkit';
import { whyData } from '../../utils/data';
import PuppetImg from '../../assets/Network.png';

const Why = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <DataSection>
        <DataTitle id="why" mb="50px">
          {t('data')}
        </DataTitle>
        <DataContent textAlign="left" mb="50px">
          {t('liabilities')}
        </DataContent>
        <DataContent textAlign="left">{t('same')}</DataContent>
        <DataTitle my="50px">{t('rid')}</DataTitle>
        <DataContent textAlign="left">{t('pain')}</DataContent>
      </DataSection>
      <BecauseSection>
        <BecauseTitle>{t('because')}</BecauseTitle>
        <BecauseWrapper>
          {whyData.map((data) => (
            <GridWrapper key={data.title}>
              <WhyImage src={data.image} alt="Image" />
              <GridSubTitle>{t(`${data.title}`)}</GridSubTitle>
              <GridContent>{t(`${data.content}`)}</GridContent>
            </GridWrapper>
          ))}
        </BecauseWrapper>
        <PlayerWrapper>
          <iframe
            src="https://www.youtube.com/embed/WfC3E5slrME"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </PlayerWrapper>
      </BecauseSection>
      <EitherTitle>{t('middle')}</EitherTitle>
      <EitherSection>
        <div className="left-grid">
          <EitherContent1>
            <Trans components={{ br: <br /> }}>proposition</Trans>
          </EitherContent1>
        </div>
        <Flex style={{ height: '100%' }}>
          <PuppetImage src={PuppetImg} alt="Puppet" />
        </Flex>
      </EitherSection>
    </Container>
  );
};

export default Why;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.primary};
`;

const DataSection = styled(Flex)`
  flex-direction: column;
  padding: 50px 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 100px 84px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 100px 400px;
  }
`;

const DataTitle = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 28px;
    line-height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 40px;
    line-height: 46px;
  }
`;

const DataContent = styled(Text)`
  font-size: 14px;
  line-height: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
    line-height: 18px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 20px;
    line-height: 23px;
  }
`;

const BecauseSection = styled(Flex)`
  flex-direction: column;
  margin: 0 20px 50px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 34px 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin: 0 250px 100px;
  }
`;

const BecauseTitle = styled(Text)`
  max-width: 320px;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textCaption};

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 600px;
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: 800px;
    margin-bottom: 60px;
    font-size: 32px;
    line-height: 37px;
  }
`;

const BecauseWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 50px;
  align-items: flex-start;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    gap: 100px;
  }
`;

const GridWrapper = styled(Flex)`
  flex-direction: column;
`;

const WhyImage = styled(Image)`
  height: 80px;
  margin-bottom: 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 160px;
  }
`;

const GridSubTitle = styled(Text)`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.textCaption};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 18px;
    line-height: 21px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 26px;
    line-height: 30px;
  }
`;

const GridContent = styled(Text)`
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 16px;
    line-height: 18px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 20px;
    line-height: 23px;
  }
`;

const EitherSection = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 50px;
  align-items: flex-start;
  margin: 0 20px;
  padding-bottom: 50px;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: 1fr 1fr;
    margin: 0 65px;
    padding-bottom: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    gap: 150px;
    margin: 0 210px;
  }
`;

const EitherTitle = styled(Text)`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 32px;
    line-height: 37px;
  }
`;

const EitherContent1 = styled(GridContent)`
  text-align: left;

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 24px;
    line-height: 28px;
  }
`;

// const EitherSubTitle = styled(Text)`
//   margin-bottom: 30px;
//   font-weight: bold;
//   font-size: 15px;
//   line-height: 17px;
//   color: ${({ theme }) => theme.colors.textCaption};

//   ${({ theme }) => theme.mediaQueries.md} {
//     margin-bottom: 25px;
//     font-size: 18px;
//     line-height: 21px;
//   }

//   ${({ theme }) => theme.mediaQueries.xl} {
//     margin-bottom: 50px;
//     font-size: 26px;
//     line-height: 30px;
//   }
// `;

// const EitherContent2 = styled(EitherContent1)`
//   margin-bottom: 0;
//   text-align: left;

//   ${({ theme }) => theme.mediaQueries.md} {
//     margin-bottom: 0;
//   }

//   ${({ theme }) => theme.mediaQueries.xl} {
//     margin-bottom: 0;
//   }
// `;

const PuppetImage = styled(Image)`
  width: 200px;
  height: 200px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 250px;
    height: 250px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 400px;
    height: 400px;
  }
`;

const PlayerWrapper = styled.div`
  margin: 50px 0 0;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 100px 0 0;
  }

  iframe {
    width: 100% !important;
    height: 170px !important;

    ${({ theme }) => theme.mediaQueries.md} {
      width: 500px !important;
      height: 282px !important;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 800px !important;
      height: 450px !important;
    }
  }
`;
