import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, SubTitle } from '../../components/Toolkit';

const Step2 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const changeHandler = useCallback(
    (nav: string) => {
      history.push(nav);
    },
    [history],
  );

  return (
    <DemoWrapper>
      <Container>
        <SubTitle mb="18px" textAlign="left">
          {t('welcome')}
        </SubTitle>
        <SubTitle mb="50px" textAlign="left">
          {t('select')}
        </SubTitle>
        <Button type="button" onClick={() => changeHandler('/demo/start')}>
          {t('user button')}
        </Button>
        <Button type="button" onClick={() => changeHandler('/demo/question-1')}>
          {t('question validator')}
        </Button>
        <Button type="button" onClick={() => changeHandler('/demo/answer-1')}>
          {t('answer validator')}
        </Button>
        <EndButton type="button" onClick={() => changeHandler('/')}>
          {t('end button')}
        </EndButton>
      </Container>
    </DemoWrapper>
  );
};

export default Step2;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const Button = styled(BaseButton)`
  min-width: 200px;
  margin-bottom: 15px;
  text-align: left;
`;

const EndButton = styled(Button)`
  position: absolute;
  left: 25px;
  bottom: 20px;
`;
