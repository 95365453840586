import BrainwashImg from '../assets/Corporate.png';
import DuckImg from '../assets/Crypto.png';
import LifeImg from '../assets/Sovereignty.png';
import PasswordImg from '../assets/Password.png';
import BiometricsImg from '../assets/Biometrics.png';
import DeviceImg from '../assets/Device.png';
import GatekeeperImg from '../assets/Gatekeeper.png';

export const navsHashLinkData = [
  {
    title: 'why',
    link: '/#why',
  },
  {
    title: 'how',
    link: '/#how',
  },
  {
    title: 'contact',
    link: '/#contact',
  },
];
export const navsLinkData = [
  {
    title: 'contact',
    link: '/contact',
  },
  {
    title: 'demo',
    link: '/demo',
  },
];

export const whyData = [
  {
    image: BrainwashImg,
    title: 'brainwash',
    content: 'cambridge',
  },
  {
    image: DuckImg,
    title: 'sitting',
    content: 'hacked',
  },
  {
    image: LifeImg,
    title: 'life',
    content: 'humanitarian',
  },
];

export const howData = [
  {
    image: PasswordImg,
    title: 'no password',
  },
  {
    image: BiometricsImg,
    title: 'no biometrics',
  },
  {
    image: DeviceImg,
    title: 'no key device',
  },
  {
    image: GatekeeperImg,
    title: 'no gatekeeper',
  },
];

export const howAllData = [
  {
    title: 'user friendly',
    content: 'intuitive',
  },
  {
    title: 'anonymous',
    content: 'encrypted',
  },
  {
    title: 'decentralized',
    content: 'community',
  },
  {
    title: 'loss proof',
    content: 'perpetual',
  },
  {
    title: 'cross platform',
    content: 'terminal',
  },
  {
    title: 'universal',
    content: 'document',
  },
];

export const words1 = [
  'chapter',
  'student',
  'tea',
  'garbage',
  'love',
  'surgery',
  'pie',
  'meaning',
  'basis',
  'mood',
  'bonus',
  'anxiety',
  'variation',
  'lady',
  'wedding',
  'speech',
  'sympathy',
  'thought',
  'departure',
  'pollution',
  'education',
  'shopping',
  'chocolate',
  'ladder',
  'reality',
  'procedure',
  'story',
  'love',
  'tongue',
  'marketing',
  'insurance',
  'youth',
  'trainer',
  'activity',
  'inflation',
  'audience',
  'candidate',
  'wedding',
  'chemistry',
  'drama',
  'quantity',
  'politics',
  'magazine',
  'apple',
  'mall',
  'entry',
  'knowledge',
  'penalty',
  'climate',
  'diamond',
  'tension',
  'economics',
  'height',
  'ambition',
  'patience',
  'insect',
  'session',
  'setting',
  'reality',
  'lab',
  'midnight',
  'injury',
  'nature',
  'poetry',
  'leader',
  'outcome',
  'sector',
  'vehicle',
  'owner',
  'winner',
  'departure',
  'gene',
  'president',
  'pollution',
  'emphasis',
  'movie',
  'library',
  'airport',
  'agency',
  'nature',
  'speech',
  'chemistry',
  'platform',
  'clothes',
  'world',
  'signature',
  'database',
  'payment',
  'camera',
  'meat',
  'selection',
  'tooth',
  'heart',
  'medicine',
  'freedom',
  'driver',
  'professor',
  'fishing',
  'outcome',
  'worker',
];

export const words2 = [
  'chapter',
  'student',
  'tea',
  'garbage',
  'dislike',
  'surgery',
  'part',
  'meaning',
  'basis',
  'mood',
  'bonus',
  'anxiety',
  'variation',
  'man',
  'wedding',
  'speech',
  'sympathy',
  'think',
  'apart',
  'pollution',
  'medicine',
  'shopping',
  'apple',
  'ladder',
  'scammer',
  'procedure',
  'story',
  'love',
  'tongue',
  'marketing',
  'insurance',
  'youth',
  'trainer',
  'activity',
  'inflation',
  'audience',
  'candidate',
  'wedding',
  'chemistry',
  'film',
  'quantity',
  'politics',
  'magazine',
  'pear',
  'mall',
  'point',
  'knowledge',
  'penalty',
  'climate',
  'diamond',
  'ship',
  'economics',
  'height',
  'ambition',
  'patience',
  'insect',
  'session',
  'setting',
  'reality',
  'laboratory',
  'midnight',
  'injury',
  'nature',
  'poetry',
  'leader',
  'persist',
  'sector',
  'vehicle',
  'owner',
  'loser',
  'departure',
  'machine',
  'president',
  'pollution',
  'emphasis',
  'drama',
  'library',
  'airport',
  'professor',
  'pollution',
  'speech',
  'chemistry',
  'plane',
  'clothes',
  'world',
  'signature',
  'data',
  'payment',
  'phone',
  'meat',
  'selection',
  'bruth',
  'heart',
  'medicine',
  'freedom',
  'driver',
  'teacher',
  'fishing',
  'outcome',
  'farmer',
];
