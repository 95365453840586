export enum ActionType {
  FETCH_USER = 'FETCH_USER',
}

interface fetchUser {
  type: ActionType.FETCH_USER;
  payload: UserInfo;
}

export type Action = fetchUser;
