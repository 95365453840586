import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  StyledButton,
  Flex,
  Image,
  Link as BaseLink,
  HashLink as BaseHashLink,
  Text,
} from '../../components/Toolkit';
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints';
import { navsHashLinkData } from '../../utils/data';

import HeroImg from '../../assets/bg.jpg';
import MenuIcon from '../../assets/Menu.png';
import CloseIcon from '../../assets/Close.png';

const Container = styled.div`
  padding: 0 40px 20px;
  background: url(${HeroImg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 40px 110px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 0 40px 160px;
  }
`;

const Wrapper = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
  padding: 96px 0 58px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 156px 0 195px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 350px 0 268px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 26px;
  line-height: 34px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 12px;
    font-size: 42px;
    line-height: 48px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 39px;
    font-size: 60px;
    line-height: 69px;
  }
`;

const SubTitle = styled(Text)`
  margin-bottom: 22px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: right;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 52px;
    font-size: 28px;
    line-height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 93px;
    font-size: 40px;
    line-height: 46px;
  }
`;

// const Content = styled(Text)`
//   font-weight: bold;
//   font-size: 20px;
//   line-height: 23px;

//   ${({ theme }) => theme.mediaQueries.md} {
//     font-size: 28px;
//     line-height: 32px;
//   }

//   ${({ theme }) => theme.mediaQueries.xl} {
//     font-size: 40px;
//     line-height: 46px;
//   }
// `;

const ButtonWrapper = styled(Flex)`
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

const Button = styled(StyledButton)`
  min-width: 164px;
  margin: 8px 0;

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 258px;
    margin: 0 40px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 502px;
    margin: 0 132px;
  }
`;

const MenuImage = styled(Image)`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const DropdownWrapper = styled(Flex)`
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.primary};
  backdrop-filter: blur(4px);
  z-index: 999;
`;

const DropdownWrap = styled(Flex)`
  flex-direction: column;
  margin-top: 70px;
`;

const Link = styled(BaseLink)`
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
`;

const HashLink = styled(BaseHashLink)`
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
`;

const CloseImage = styled(MenuImage)`
  margin-top: 24px;
`;

const Hero = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const isMobile = isXs || isSm || isMd;

  const [isDropdown, setIsDropdown] = useState(false);

  const menuHandler = useCallback(() => {
    setIsDropdown(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsDropdown(false);
  }, []);

  const demoHandler = useCallback(() => {
    history.push('/demo/step-2');
  }, [history]);

  const Dropdown = () => (
    <DropdownWrapper>
      <DropdownWrap>
        <Link to="/" onClick={closeHandler}>
          {t('home')}
        </Link>
        {navsHashLinkData.map((nav) => (
          <HashLink key={nav.title} to={nav.link} onClick={closeHandler}>
            {t(`${nav.title}`)}
          </HashLink>
        ))}
        <Link to="/demo/step-2">{t('demo')}</Link>
      </DropdownWrap>
      <CloseImage src={CloseIcon} alt="Close Icon" onClick={closeHandler} />
    </DropdownWrapper>
  );

  return (
    <>
      <Container>
        <Wrapper>
          <Title textAlign="right">{t('digital world')}</Title>
          <SubTitle>{t('power')}</SubTitle>
        </Wrapper>
        <Flex align={isMobile ? 'space-between' : 'center'}>
          <ButtonWrapper>
            <Button onClick={demoHandler}>{t('test button')}</Button>
          </ButtonWrapper>
          {isMobile && <MenuImage src={MenuIcon} alt="Menu Icon" onClick={menuHandler} />}
        </Flex>
      </Container>
      {isDropdown && <Dropdown />}
    </>
  );
};

export default Hero;
