import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button, Text } from '../../components/Toolkit';

const Step3 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const okHandler = useCallback(() => {
    history.push('/demo/step-1');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Text textAlign="left" mb="35px">
          {t('after pay')}
        </Text>
        <Button type="button" onClick={okHandler}>
          {t('begin setup')}
        </Button>
      </Container>
    </DemoWrapper>
  );
};

export default Step3;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;
