import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, Flex, SubTitle, Title } from '../../components/Toolkit';
import { getRandomInt } from '../../utils/getRandomInt';
import { words1, words2 } from '../../utils/data';

const Q2 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [alert, setAlert] = useState('');
  const [isAlert, setIsAlert] = useState(true);
  const [answerId, setAnswerId] = useState(0);

  useEffect(() => {
    let counts = 0;
    setIsAlert(true);
    const interval = setInterval(() => {
      if (counts === 0) setAlert('waiting answer1');
      else if (counts === 1) setAlert('waiting answer2');
      else if (counts === 2) setAlert('waiting answer3');
      else {
        clearInterval(interval);
        setIsAlert(false);
      }
      counts += 1;
    }, 1000);
  }, []);

  useEffect(() => {
    const rand = getRandomInt(1, words1.length);
    setAnswerId(rand);
  }, []);

  const changeHandler = useCallback(
    (value: string) => {
      const _rate = [value];
      localStorage.setItem('validAnswer', _rate.toString());
      history.push('/demo/answer-3');
    },
    [history],
  );

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('aval page')}
        </Title>

        <SubTitle textAlign="left" mb="15px">
          {t('validate challenge')}
        </SubTitle>
        <Button1>{words1[answerId]}</Button1>

        {isAlert ? (
          <SubTitle textAlign="left" mt="15px">
            {t(alert)}
          </SubTitle>
        ) : (
          <>
            <SubTitle textAlign="left" my="15px">
              {t('answer received')}
            </SubTitle>
            <Button1>{words2[answerId]}</Button1>
            <SubTitle textAlign="left" my="15px">
              {t('answers match')}
            </SubTitle>
            <ButtonWrapper>
              <Button2 onClick={() => changeHandler('T')}>{t('yes button')}</Button2>
              <Button2 onClick={() => changeHandler('F')}>{t('no button')}</Button2>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </DemoWrapper>
  );
};

export default Q2;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const Button1 = styled.div`
  min-width: 100px;
  padding: 12px 25px;
  background: ${({ theme }) => theme.colors.secondary};
  color: red;
  border-radius: 20px;
  box-sizing: border-box;
  border: none;
  font-size: 18px;
  line-height: 1.3;
`;

const ButtonWrapper = styled(Flex)`
  justify-content: space-between;
`;

const Button2 = styled(BaseButton)`
  min-width: 100px;
`;
