import { DefaultTheme } from 'styled-components';
import base from './base';

const antTheme: DefaultTheme = {
  ...base,
  colors: {
    primary: '#050E37',
    secondary: '#FFCC18',
    hover: '#30D1FF',
    textPrimary: '#fff',
    textSecondary: '#D2ECF2',
    textCaption: '#56BAD1',
  },
  shadows: {
    shadow1:
      '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    shadow2:
      '0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',
    shadow3:
      '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)',
  },
};

export default antTheme;
