import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  Flex,
  Image as StyledImage,
  StyledButton,
  Text as StyledText,
  ExternalLink,
} from '../../components/Toolkit';
// import { ModalContext } from '../../providers';
import TwitterIcon from '../../assets/Twitter.png';
import EmailIcon from '../../assets/Email.png';

const Socials = () => {
  const { t } = useTranslation();
  const history = useHistory();
  // const { toggleModal } = useContext(ModalContext);

  // const contactHandler = useCallback(() => {
  //   toggleModal();
  // }, [toggleModal]);

  const demoHandler = useCallback(() => {
    history.push('/demo/step-2');
  }, [history]);

  return (
    <Container id="contact">
      <TestButton onClick={demoHandler}>{t('test button')}</TestButton>
      <ContactWrapper>
        <Text>{t('contact us')}</Text>
        <Flex>
          <ExternalLink href="mailto:contact@e-sov.org" target="_blank">
            <Image src={EmailIcon} alt="EmailIcon" />
          </ExternalLink>
          <ExternalLink href="https://twitter.com/eSov_org/" target="_blank">
            <Image src={TwitterIcon} alt="TwitterIcon" />
          </ExternalLink>
        </Flex>
      </ContactWrapper>
    </Container>
  );
};

export default Socials;

const Container = styled(Flex)`
  flex-direction: column;
  padding: 50px 0 0;
  background: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 100px 0 0;
  }
`;

const ContactButton = styled(StyledButton)`
  min-width: 140px;
  margin: 0 0 16px;
  padding: 4px 7px;
  font-size: 17px;
  line-height: 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 231px;
    margin: 0 80px 0 0;
    padding: 8px 25px;
    font-size: 24px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 432px;
    margin: 0 0 100px;
    padding: 15px 70px;
    font-size: 40px;
    line-height: 46px;
  }
`;

const TestButton = styled(ContactButton)`
  margin: 0;
`;

const ContactWrapper = styled.div`
  margin: 50px 0;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 100px 0;
  }
`;

const Text = styled(StyledText)`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #d2ecf2;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 60px;
    font-size: 32px;
    line-height: 37px;
  }
`;

const Image = styled(StyledImage)`
  width: 60px;
  height: 60px;
  margin: 0 30px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100px;
    height: 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 130px;
    height: 130px;
  }
`;
