import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Flex } from '../Toolkit';

const Container = styled(Flex)<{ isBottom?: boolean }>`
  flex-direction: column;
  position: absolute;
  top: ${({ isBottom }) => (isBottom ? 'auto' : '40px')};
  bottom: ${({ isBottom }) => (isBottom ? '50px' : 'auto')};
  left: 0;
  background: #56bad1;
  box-shadow: ${({ theme }) => theme.shadows.shadow3};
  border-radius: 4px;
  z-index: 100;
`;

interface DropdownProps {
  options: string[];
  isBottom?: boolean;
  closeHandler: (idx: number) => void;
}

const Dropdown: FC<DropdownProps> = ({ options, isBottom, closeHandler }) => {
  const optionHandler = useCallback(
    (idx: number) => {
      closeHandler(idx);
    },
    [closeHandler],
  );

  return (
    <Container isBottom={isBottom}>
      {options.map((option, id) => (
        <Text key={id} onClick={() => optionHandler(id)}>
          {option}
        </Text>
      ))}
    </Container>
  );
};

export default Dropdown;

const Text = styled.div`
  width: calc(100% - 32px);
  margin: 5px 16px;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.textPrimary};

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 28px;
    line-height: 32px;
  }
`;
