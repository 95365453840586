import { Action, ActionType } from '../actionTypes';

const initialState = {
  loading: false,
  user: {
    id: '',
    name: '',
    birthday: null,
    words: [],
    token: '',
    secret: '',
    isValidated: false,
  },
  error: '',
};

const userReducer = (state: UserState = initialState, action: Action): UserState => {
  switch (action.type) {
    case ActionType.FETCH_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
