import { changeLanguage } from 'i18next';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Select from '../Select';
import { Flex, Image, Link as BaseLink, ExternalLink, HashLink as BaseHashLink } from '../Toolkit';
import useMatchBreakpoints from '../../hooks/useMatchBreakpoints';
import { navsHashLinkData } from '../../utils/data';

import LogoImg from '../../assets/logo.png';
import CloseIcon from '../../assets/Close.png';
import MenuIcon from '../../assets/Menu.png';
import EnIcon from '../../assets/en.png';
import FrIcon from '../../assets/fr.png';

const options = ['En', 'Fr'];
const flags = [EnIcon, FrIcon];

const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isXs, isSm, isMd } = useMatchBreakpoints();
  const isMobile = isXs || isSm || isMd;

  const [isDropdown, setIsDropdown] = useState(false);
  const [lang, setLang] = useState(options[0]);
  const [flag, setFlag] = useState(flags[0]);

  const menuHandler = useCallback(() => {
    setIsDropdown(true);
  }, []);

  const closeHandler = useCallback(() => {
    setIsDropdown(false);
  }, []);

  const demoHandler = useCallback(() => {
    setIsDropdown(false);
  }, []);

  // const contactHandler1 = useCallback(() => {
  //   toggleModal();
  // }, [toggleModal]);

  const onLangChange = useCallback((id: number) => {
    setLang(options[id]);
    setFlag(flags[id]);
    changeLanguage(options[id].toLowerCase());
  }, []);

  const Dropdown = () => (
    <DropdownWrapper>
      <DropdownWrap>
        <MobileLink to="/" onClick={closeHandler}>
          {t('home')}
        </MobileLink>
        {navsHashLinkData.map((nav) => (
          <MobileHashLink key={nav.title} to={nav.link} onClick={closeHandler}>
            {t(`${nav.title}`)}
          </MobileHashLink>
        ))}
        <MobileLink to="/demo" onClick={demoHandler}>
          {t('demo')}
        </MobileLink>
      </DropdownWrap>
      <CloseImage src={CloseIcon} alt="Close Icon" onClick={closeHandler} />
    </DropdownWrapper>
  );

  return (
    <Container>
      <BaseLink to="/">
        <LogoImage src={LogoImg} alt="Logo" />
      </BaseLink>
      {!isMobile && (
        <>
          <Flex>
            <Link to="/">{t('home')}</Link>
            {navsHashLinkData.map((nav) => (
              <HashLink key={nav.title} to={nav.link}>
                {t(`${nav.title}`)}
              </HashLink>
            ))}
            <Link to="/demo/step-2">{t('demo')}</Link>
          </Flex>
          <Select lang={lang} flag={flag} options={options} cbHandler={onLangChange} />
        </>
      )}
      {isMobile && (
        <LangWrapper>
          <Select lang={lang} flag={flag} options={options} cbHandler={onLangChange} />
        </LangWrapper>
      )}
      {isMobile && pathname !== '/' && (
        <Flex align="space-between" style={{ width: '100%', marginTop: 10 }}>
          <Button href="mailto:contact@e-sov.org" target="_blank">
            {t('contact us')}
          </Button>
          <MenuImage src={MenuIcon} alt="Menu Icon" onClick={menuHandler} />
        </Flex>
      )}
      {isDropdown && <Dropdown />}
    </Container>
  );
};

export default Header;

const Container = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: calc(100% - 40px);
  margin: 10px 20px 0;
  z-index: 100;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    align-items: center;
    width: calc(100% - 80px);
    margin: 24px 40px 0;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: calc(100% - 160px);
    margin: 50px 80px 0;
  }
`;

const LogoImage = styled(Image)`
  width: 237px;
  height: 63px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 230px;
    height: 60px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 525px;
    height: 140px;
  }
`;

const Link = styled(BaseLink)`
  margin-left: 25px;
  font-size: 30px;
  line-height: 34px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 25px;
    font-size: 18px;
    line-height: 21px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-left: 50px;
    font-size: 32px;
    line-height: 37px;
  }
`;

const HashLink = styled(BaseHashLink)`
  margin-left: 25px;
  font-size: 30px;
  line-height: 34px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 25px;
    font-size: 18px;
    line-height: 21px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-left: 50px;
    font-size: 32px;
    line-height: 37px;
  }
`;

const DropdownWrapper = styled(Flex)`
  position: fixed;
  left: 0;
  top: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.primary};
  backdrop-filter: blur(4px);
  z-index: 999;
`;

const DropdownWrap = styled(Flex)`
  flex-direction: column;
  margin-top: 70px;
`;

const MobileLink = styled(BaseLink)`
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
`;

const MobileHashLink = styled(BaseHashLink)`
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
`;

const CloseImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-top: 24px;
  cursor: pointer;
`;

const Button = styled(ExternalLink)`
  margin: 8px 0;
  padding: 5px 20px;
  background: ${({ theme }) => theme.colors.primary};
  border: ${({ theme }) => `3px solid ${theme.colors.secondary}`};
  border-radius: 17px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textSecondary};

  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 258px;
    margin: 0 40px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    min-width: 502px;
    margin: 0 132px;
  }
`;

const MenuImage = styled(Image)`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;

const LangWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
`;
