import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, Flex, SubTitle, Title, Text } from '../../components/Toolkit';
// import countOfUnique from '../../utils/countOfUnique';
import { getRandomInt } from '../../utils/getRandomInt';

const A3 = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const correct = getRandomInt(1, 12);

  // const [answers, setAnswers] = useState(0);

  // useEffect(() => {
  //   const temp = localStorage.getItem('validAnswer');
  //   if (temp) {
  //     const _temp = temp.split(',');
  //     const unique = countOfUnique(_temp);
  //     setAnswers(unique.T);
  //   }
  // }, []);

  const changeHandler = useCallback(() => {
    history.push('/demo/answer-4');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('aval page')}
        </Title>

        <SubTitle textAlign="left" mb="15px">
          {t('recovery results')}
        </SubTitle>
        <TextWrapper>
          <Text textAlign="left">{t('challenges sent')}</Text>
          <Text textAlign="left" color="secondary">
            12
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text textAlign="left">{t('challenges validated')}</Text>
          <Text textAlign="left" color="secondary">
            {correct}
          </Text>
        </TextWrapper>

        <SubTitle textAlign="left" mt="80px" mb="10px">
          {t('release')}
        </SubTitle>
        <ButtonWrapper>
          <Button onClick={changeHandler}>{t('yes button')}</Button>
          <Button onClick={changeHandler}>{t('no button')}</Button>
        </ButtonWrapper>
      </Container>
    </DemoWrapper>
  );
};

export default A3;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const ButtonWrapper = styled(Flex)`
  justify-content: space-between;
`;

const Button = styled(BaseButton)`
  min-width: 100px;
`;

const TextWrapper = styled(Flex)`
  justify-content: space-between;
`;
