import { Link as BaseLink } from 'react-router-dom';
import { HashLink as BaseHashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { space, layout } from 'styled-system';
import LinkProps from './types';

export const Link = styled(BaseLink)<LinkProps>`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;

  ${layout}
  ${space}
`;

export const ExternalLink = styled.a<LinkProps>`
  width: auto;
  text-decoration: none;

  ${layout}
  ${space}
`;

export const HashLink = styled(BaseHashLink)`
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;

  ${layout}
  ${space}
`;
