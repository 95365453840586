/* eslint-disable import/no-named-as-default */
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { DayValue } from 'react-modern-calendar-datepicker';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button, Input, Label, SubTitle, Title, Text } from '../../components/Toolkit';

import 'react-modern-calendar-datepicker/lib/DatePicker.css';

const Step5 = () => {
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY ?? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.user);

  const [name, setName] = useState({
    firstName: '',
    lastName: '',
  });
  const [day, setDay] = useState<DayValue>(null);
  const [token, setToken] = useState('');

  const handleVerify = useCallback((value) => {
    setToken(value);
  }, []);

  const changeHandler = useCallback(
    (e) => {
      setName({
        ...name,
        [e.target.name]: e.target.value,
      });
    },
    [name],
  );

  const okHandler = useCallback(
    (e) => {
      e.preventDefault();
      const isCorrect =
        user.name === name.firstName + name.lastName &&
        user.birthday?.day === day?.day &&
        user.birthday?.month === day?.month &&
        user.birthday?.year === day?.year;
      if (isCorrect) history.push('/demo/step-6');
      else history.push('/demo/wrong');
    },
    [history, user, name, day],
  );

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('user page')}
        </Title>
        <SubTitle textAlign="left" mb="15px">
          {t('step 5')}
        </SubTitle>
        <Text textAlign="left" mb="15px">
          {t('recommend')}
        </Text>
        <form onSubmit={okHandler}>
          <Label htmlFor="firstName">
            <Input
              id="firstName"
              name="firstName"
              value={name.firstName}
              required
              placeholder={t('first name')}
              onChange={changeHandler}
            />
          </Label>
          <Label htmlFor="lastNmae">
            <Input
              id="lastName"
              name="lastName"
              value={name.lastName}
              required
              placeholder={t('last name')}
              onChange={changeHandler}
            />
          </Label>
          <Label htmlFor="birth">
            <DatePicker
              value={day}
              onChange={setDay}
              inputPlaceholder={t('date of birth')}
              shouldHighlightWeekends
            />
          </Label>
          <RecapchaWrapper>
            <ReCAPTCHA sitekey={reCaptchaKey} onChange={handleVerify} />
          </RecapchaWrapper>
          <Button type="submit" disabled={!token || !day}>
            {t('request button')}
          </Button>
        </form>
      </Container>
    </DemoWrapper>
  );
};

export default Step5;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  .DatePicker {
    width: 100%;

    .DatePicker__input {
      text-align: left;
    }
  }

  .Calendar {
    background: #56bad1;

    .Calendar__monthText,
    .Calendar__yearText,
    .Calendar__yearSelectorText,
    .Calendar__monthSelectorItemText {
      color: ${({ theme }) => theme.colors.primary};
    }

    .Calendar__day {
      width: 33.32px;
      height: 33.32px;
      min-height: 33.32px;
    }

    .Calendar__sectionWrapper {
      height: 200px;
      min-height: 200px;
    }

    .Calendar__header {
      margin-top: 12px;
    }

    .Calendar__weekDay {
      color: green;
    }
  }
`;

const RecapchaWrapper = styled.div`
  margin: 10px 0 30px;
`;
