import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from '../Toolkit';

const Container = styled(Flex)`
  padding: 160px 0 0;
  background: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 180px 0 100px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    padding: 300px 0 100px;
  }
`;

const DemoWrapper = styled.div`
  width: 100%;
  position: relative;
  // border-top: 8px solid #fff;
  // border-bottom: 8px solid #fff;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 360px;
    border: none;
  }
`;

const Demo: FC = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Container>
      <DemoWrapper>{children}</DemoWrapper>
    </Container>
  );
};

export default Demo;
