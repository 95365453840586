import styled from 'styled-components';
import Hero from './Hero';
import Why from './Why';
import How from './How';
import Socials from './Socials';

const Container = styled.div``;

const Home = () => {
  return (
    <Container>
      <Hero />
      <Why />
      <How />
      <Socials />
    </Container>
  );
};

export default Home;
