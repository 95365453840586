import styled from 'styled-components';
import { space, typography, border, color as baseColor } from 'styled-system';
import { TextProps } from './types';

export const Text = styled.div<TextProps>`
  font-weight: ${({ weight }) => (weight ? weight : '400')};
  font-size: ${({ size }) => (size ? `${size}px` : '18px')};
  line-height: ${({ height }) => (height ? `${height}px` : '1.3')};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : 'center')};
  text-transform: ${({ transform }) => (transform ? transform : 'inherit')};
  color: ${({ color, theme }) => (color ? color : theme.colors.textSecondary)};

  ${space}
  ${typography}
  ${border}
  ${baseColor}
`;

export const Title = styled(Text)`
  font-weight: 700;
  font-size: 26px;
`;

export const SubTitle = styled(Title)`
  font-weight: 500;
  font-size: 22px;
`;
