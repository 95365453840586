import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import { Flex, Image, Text } from '../../components/Toolkit';
import { howData, howAllData } from '../../utils/data';

const How = () => {
  const { t } = useTranslation();

  return (
    <Container id="how">
      <Title>{t('recover')}</Title>
      <NoWrapper>
        {howData.map((data) => (
          <NoWrap key={data.title}>
            <NoImage src={data.image} alt="No Image" />
            <NoSubTitle>{t(`${data.title}`)}</NoSubTitle>
          </NoWrap>
        ))}
      </NoWrapper>
      {/* <EveryoneWrapper>
        <EveryoneTitle>{t('self sovereignty')}</EveryoneTitle>
        <EveryoneContent>{t('easy website')}</EveryoneContent>
      </EveryoneWrapper> */}
      <AllWrapper>
        <AllTitle>
          {/* {t('self management')} */}
          <Trans components={{ br: <br /> }}>self management</Trans>
        </AllTitle>
        <AllWrap>
          {howAllData.map((data) => (
            <div key={data.title}>
              <AllSubTitle>{t(`${data.title}`)}</AllSubTitle>
              <AllContent>{t(`${data.content}`)}</AllContent>
            </div>
          ))}
        </AllWrap>
      </AllWrapper>
    </Container>
  );
};

export default How;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
`;

const Title = styled(Text)`
  max-width: 320px;
  margin: 0 20px 48px;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${({ theme }) => theme.colors.textCaption};
  text-align: center;

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 600px;
    margin: 0 84px 50px;
    font-size: 28px;
    line-height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: 800px;
    margin: 0 400px 100px;
    font-size: 40px;
    line-height: 46px;
  }
`;

const NoWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 50px;
  margin: 0 38px 50px;
  width: calc(100% - 76px);

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 50px;
    margin: 0 55px 100px;
    width: calc(100% - 110px);
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    gap: 130px;
    margin: 0 280px 100px;
    width: calc(100% - 560px);
  }
`;

const NoWrap = styled(Flex)`
  flex-direction: column;
`;

const NoImage = styled(Image)`
  width: 100px;
  height: 100px;
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaQueries.xl} {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
`;

const NoSubTitle = styled(Text)`
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.textSecondary};

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 18px;
    line-height: 21px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    font-size: 26px;
    line-height: 30px;
  }
`;

// const EveryoneWrapper = styled(Flex)`
//   flex-direction: column;
//   margin: 0 20px 50px;

//   ${({ theme }) => theme.mediaQueries.md} {
//     margin: 0 84px 100px;
//   }

//   ${({ theme }) => theme.mediaQueries.xl} {
//     margin: 0 400px 100px;
//   }
// `;

const EveryoneTitle = styled(Text)`
  max-width: 320px;
  margin-bottom: 36px;
  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 600px;
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 28px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    max-width: 800px;
    font-size: 32px;
    line-height: 37px;
  }
`;

// const EveryoneContent = styled(Text)`
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 16px;
//   text-align: left;

//   ${({ theme }) => theme.mediaQueries.md} {
//     font-size: 16px;
//     line-height: 18px;
//   }

//   ${({ theme }) => theme.mediaQueries.xl} {
//     font-size: 24px;
//     line-height: 28px;
//   }
// `;

const AllWrapper = styled(Flex)`
  flex-direction: column;
  margin: 0 20px;

  ${({ theme }) => theme.mediaQueries.md} {
    margin: 0 34px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin: 0 280px;
  }
`;

const AllTitle = styled(EveryoneTitle)`
  margin-bottom: 30px;
`;

const AllWrap = styled.div`
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  row-gap: 30px;

  ${({ theme }) => theme.mediaQueries.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr;
    column-gap: 50px;
    row-gap: 70px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    column-gap: 60px;
    row-gap: 80px;
  }
`;

const AllSubTitle = styled(Text)`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 15px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.textCaption};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 21px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 27px;
    font-size: 26px;
    line-height: 30px;
  }
`;

const AllContent = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.textSecondary};

  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 18px;
    font-size: 16px;
    line-height: 18px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 28px;
  }
`;
