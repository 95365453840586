import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, SubTitle, Title } from '../../components/Toolkit';

const Q3 = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const okHandler = useCallback(() => {
    history.push('/demo/step-2');
  }, [history]);

  const endHandler = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px">{t('qval page')}</Title>
        <SubTitle textAlign="left" mb="35px">
          <Trans components={{ br: <br /> }}>reward coming</Trans>
        </SubTitle>

        <ButtonsWrapper>
          <Button type="button" onClick={okHandler}>
            {t('test other button')}
          </Button>
          <Button type="button" onClick={endHandler}>
            {t('end button')}
          </Button>
        </ButtonsWrapper>
      </Container>
    </DemoWrapper>
  );
};

export default Q3;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Button = styled(BaseButton)`
  margin-bottom: 25px;
`;
