import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button, SubTitle, Title, Text } from '../../components/Toolkit';

const WrongInfo = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const retryHandler = useCallback(() => {
    history.push('/demo/step-5');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('user page')}
        </Title>
        <SubTitle textAlign="left" mb="15px">
          {t('step 5')}
        </SubTitle>
        <Text textAlign="left" mb="45px">
          {t('no record')}
        </Text>
        <Button type="button" onClick={retryHandler}>
          {t('retry button')}
        </Button>
      </Container>
    </DemoWrapper>
  );
};

export default WrongInfo;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
`;
