import { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import {
  Button as BaseButton,
  Flex,
  Input as BaseInput,
  Link,
  SubTitle,
  Title,
  Text,
} from '../../components/Toolkit';
import { fetchUserHandler } from '../../redux/actions';

const Challenge = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);

  const [challenges, setChallenges] = useState<ChallengeProp[]>([{ 1: '' }, { 2: '' }]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  const addHandler = useCallback(
    (e) => {
      e.preventDefault();
      setIsEdit(false);
      const start = challenges.length;

      setChallenges([...challenges, ...[{ [start + 1]: '' }, { [start + 2]: '' }]]);
    },
    [challenges],
  );

  const changeHandler = useCallback(
    (id: number, e) => {
      const _challenges = [...challenges];
      _challenges[id][e.target.name] = e.target.value;
      setChallenges(_challenges);
    },
    [challenges],
  );

  const okHandler = useCallback(() => {
    const _challenges = [...challenges];
    _challenges.splice(challenges.length - 2, 2);
    const newUser = {
      ...user,
      words: _challenges,
    };
    dispatch(fetchUserHandler(newUser));
    history.push('/demo/user/secret');
  }, [challenges, dispatch, history, user]);

  const editHandler = useCallback(
    (idx: number) => {
      const _challenges = [...challenges];
      _challenges.splice(challenges.length - 2, 2);
      setChallenges(_challenges);
      setEditId(idx);
      setIsEdit(true);
    },
    [challenges],
  );

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px">{t('user page')}</Title>
        <SubTitle textAlign="left" mb="15px">
          {t('step 2')}
        </SubTitle>
        <Text textAlign="left" mb="25px">
          <Trans components={{ br: <br /> }}>3 memories</Trans>{' '}
          <Link to="/demo/tutorial" style={{ fontSize: 18, lineHeight: 1.3 }}>
            {t('tutorial link')}
          </Link>
        </Text>

        <form style={{ marginBottom: 15 }} onSubmit={addHandler}>
          {!isEdit
            ? challenges.length < 7 &&
              challenges
                .slice(challenges.length - 2, challenges.length)
                .map((element) => (
                  <Input
                    key={Object.keys(element)[0]}
                    name={Object.keys(element)[0]}
                    value={Object.values(element)[0]}
                    required
                    placeholder={t(`Word ${Object.keys(element)[0]}`)}
                    onChange={(e) => changeHandler(parseInt(Object.keys(element)[0]) - 1, e)}
                  />
                ))
            : challenges
                .slice(editId * 2 - 2, editId * 2)
                .map((element) => (
                  <Input
                    key={Object.keys(element)[0]}
                    name={Object.keys(element)[0]}
                    value={Object.values(element)[0]}
                    required
                    placeholder={t('word count', { count: Number(Object.keys(element)[0]) })}
                    onChange={(e) => changeHandler(parseInt(Object.keys(element)[0]) - 1, e)}
                  />
                ))}

          <ButtonsWrapper>
            {challenges.length < 7 && (
              <AddButton type="submit" disabled={challenges.length > 6}>
                {isEdit ? t('update challenge') : t('add challenge')}
              </AddButton>
            )}
            {challenges.length > 6 && (
              <Button type="button" disabled={isEdit} onClick={okHandler}>
                {t('ok button')}
              </Button>
            )}
          </ButtonsWrapper>
        </form>
        {challenges.slice(0, challenges.length - 2).map(
          (element, id) =>
            id % 2 === 0 && (
              <PainWrapper key={id}>
                <Text color="secondary">{t('challenge count', { count: id / 2 + 1 })}</Text>
                <EditButton disabled={isEdit} onClick={() => editHandler(id / 2 + 1)}>
                  {t('edit')}
                </EditButton>
              </PainWrapper>
            ),
        )}
      </Container>
    </DemoWrapper>
  );
};

export default Challenge;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const Input = styled(BaseInput)`
  margin: 0 0 15px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Button = styled(BaseButton)`
  margin-bottom: 15px;
`;

const AddButton = styled(Button)`
  padding: 12px 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.textPrimary};

  &:hover:not(:active):not(:disabled) {
    background: transparent;
    border: none;
  }
`;

const PainWrapper = styled(Flex)`
  justify-content: space-between;
  margin: 10px 0;
`;

const EditButton = styled(Button)`
  width: fit-content;
  min-width: fit-content;
  margin: 0;
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.secondary};

  &:hover:not(:active):not(:disabled) {
    background: transparent;
    border: none;
  }
`;
