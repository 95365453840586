/* eslint-disable import/no-named-as-default */
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import {
  Button as BaseButton,
  Input as BaseInput,
  SubTitle,
  Title,
} from '../../components/Toolkit';

type ChallengeProp = {
  [name: string]: string;
};

const Step6 = () => {
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY ?? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useSelector((state: RootState) => state.user);

  const [alert1, setAlert1] = useState('');
  const [alert2, setAlert2] = useState('');
  const [countsOfNum, setCountsOfNum] = useState(0);
  const [token, setToken] = useState('');
  const [challenges, setChallenges] = useState<ChallengeProp[]>([{ 1: '' }, { 2: '' }]);
  const [start, setStart] = useState(1);

  useEffect(() => {
    let counts = 0;
    const interval = setInterval(() => {
      setCountsOfNum((count) => count + 1);
      if (counts === 0) setAlert1('finding 10');
      else if (counts === 1) setAlert1('finding 11');
      else if (counts === 2) setAlert1('finding 12');
      else if (counts === 3) setAlert2('finding 20');
      else if (counts === 4) setAlert2('finding 21');
      else if (counts === 5) setAlert2('finding 22');
      else clearInterval(interval);
      counts += 1;
    }, 1000);
  }, []);

  useEffect(() => {
    const words = user.words;
    const newWords: ChallengeProp[] = [];
    for (let i = 1; i < words.length + 1; i++) {
      if (i % 2 !== 0) newWords.push(words[i]);
      else newWords.push({ [i - 1]: '' });
    }
    setChallenges(newWords);
  }, [user]);

  const changeHandler = useCallback(
    (id: number, e) => {
      const _challenges = [...challenges];
      _challenges[id][e.target.name] = e.target.value;
      setChallenges(_challenges);
    },
    [challenges],
  );

  const handleVerify = useCallback((value) => {
    setToken(value);
  }, []);

  const nextHandler = useCallback(() => {
    setStart((prev) => prev + 1);
  }, []);

  const sendHandler = useCallback(() => {
    localStorage.setItem('answer', JSON.stringify(challenges));
    history.push('/demo/step-7');
  }, [challenges, history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('user page')}
        </Title>
        {countsOfNum < 6 ? (
          <>
            <SubTitle mb="25px" textAlign="left">
              {t(alert1)}
            </SubTitle>
            <SubTitle textAlign="left">{t(alert2)}</SubTitle>
          </>
        ) : (
          <>
            <SubTitle textAlign="left" mb="15px">
              {t('step 6')}
            </SubTitle>

            <form>
              {challenges.slice(2 * start - 2, start * 2).map((element, id) => (
                <Input
                  key={Object.keys(element)[0]}
                  name={Object.keys(element)[0]}
                  value={Object.values(element)[0]}
                  readOnly={id % 2 === 0 ? true : false}
                  required
                  placeholder={t('answer')}
                  onChange={(e) => changeHandler(parseInt(Object.keys(element)[0]), e)}
                />
              ))}

              <RecapchaWrapper>
                <ReCAPTCHA sitekey={reCaptchaKey} onChange={handleVerify} />
              </RecapchaWrapper>

              <Button
                type="button"
                disabled={!token}
                onClick={2 * start === challenges.length ? sendHandler : nextHandler}
              >
                {2 * start === challenges.length ? t('send button') : t('next button')}
              </Button>
            </form>
          </>
        )}
      </Container>
    </DemoWrapper>
  );
};

export default Step6;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const Input = styled(BaseInput)`
  margin: 0 0 15px;
`;

const RecapchaWrapper = styled.div`
  margin: 10px 0 30px;
`;

const Button = styled(BaseButton)`
  margin-bottom: 15px;
`;
