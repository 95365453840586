import { FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toast';
import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import configureStore from './redux';
import theme from './styles/theme';
import translationEn from './translations/en.json';
import translationFr from './translations/fr.json';

use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    fr: { translation: translationFr },
  },
  lng: 'en',
  fallbackLng: 'en',
});

const Provider: FC = ({ children }) => {
  const store = configureStore();

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
      <ToastContainer position="top-right" delay={3000} />
    </ReduxProvider>
  );
};

export default Provider;
