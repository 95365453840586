/* eslint-disable import/no-named-as-default */
import { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, Flex, SubTitle, Title } from '../../components/Toolkit';

const A1 = () => {
  const reCaptchaKey = process.env.REACT_APP_SITE_KEY ?? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

  const { t } = useTranslation();
  const history = useHistory();

  const [token, setToken] = useState('');
  const [alert, setAlert] = useState('');
  const [countsOfNum, setCountsOfNum] = useState(0);

  useEffect(() => {
    let counts = 0;
    const interval = setInterval(() => {
      setCountsOfNum((count) => count + 1);
      if (counts === 0) setAlert('.');
      else if (counts === 1) setAlert('..');
      else if (counts === 2) setAlert('...');
      else clearInterval(interval);
      counts += 1;
    }, 1000);
  }, []);

  const handleVerify = useCallback((value) => {
    setToken(value);
  }, []);

  const changeHandler = useCallback(() => {
    history.push('/demo/answer-2');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('aval page')}
        </Title>
        {countsOfNum < 4 ? (
          <SubTitle textAlign="left">{alert}</SubTitle>
        ) : (
          <>
            <SubTitle textAlign="left" mb="15px">
              /!\ <Trans components={{ br: <br /> }}>request received</Trans>
            </SubTitle>

            <RecapchaWrapper>
              <ReCAPTCHA sitekey={reCaptchaKey} onChange={handleVerify} />
            </RecapchaWrapper>

            <ButtonWrapper>
              <Button type="submit" disabled={!token} onClick={changeHandler}>
                {t('yes button')}
              </Button>
              <Button type="submit" disabled={!token} onClick={changeHandler}>
                {t('no button')}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </DemoWrapper>
  );
};

export default A1;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const RecapchaWrapper = styled.div`
  margin: 10px 0 30px;
`;

const ButtonWrapper = styled(Flex)`
  justify-content: space-between;
`;

const Button = styled(BaseButton)`
  min-width: 100px;
`;
