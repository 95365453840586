import { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, Flex, SubTitle, Title, Text } from '../../components/Toolkit';
import { getRandomInt } from '../../utils/getRandomInt';

const Q2 = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const correct = getRandomInt(1, 12);

  const [alert, setAlert] = useState('');
  const [countsOfNum, setCountsOfNum] = useState(0);

  useEffect(() => {
    let counts = 0;
    const interval = setInterval(() => {
      setCountsOfNum((count) => count + 1);
      if (counts === 0) setAlert('waiting quorum1');
      else if (counts === 1) setAlert('waiting quorum2');
      else if (counts === 2) setAlert('waiting quorum3');
      else clearInterval(interval);
      counts += 1;
    }, 1000);
  }, []);

  const changeHandler = useCallback(() => {
    history.push('/demo/question-3');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('qval page')}
        </Title>
        {countsOfNum < 4 ? (
          <>
            <SubTitle textAlign="left">
              <Trans components={{ br: <br /> }}>thank you 1</Trans>
            </SubTitle>
            <br />
            <SubTitle textAlign="left" mb="15px">
              {t(alert)}
            </SubTitle>
          </>
        ) : (
          <>
            <SubTitle textAlign="left" mb="15px">
              {t('recovery results')}
            </SubTitle>
            <TextWrapper>
              <Text textAlign="left">{t('challenges sent')}</Text>
              <Text textAlign="left" color="secondary">
                12
              </Text>
            </TextWrapper>
            <TextWrapper>
              <Text textAlign="left">{t('challenges validated')}</Text>
              <Text textAlign="left" color="secondary">
                {correct}
              </Text>
            </TextWrapper>

            <SubTitle textAlign="left" mt="80px" mb="10px">
              {t('release')}
            </SubTitle>
            <ButtonWrapper>
              <Button onClick={changeHandler}>{t('yes button')}</Button>
              <Button onClick={changeHandler}>{t('no button')}</Button>
            </ButtonWrapper>
          </>
        )}
      </Container>
    </DemoWrapper>
  );
};

export default Q2;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};

  input {
    width: calc(100% - 24px);
    height: auto;
    margin: 0 0 20px;
    padding: 10px;
    background: transparent;
    border-radius: 10px;
    border: ${({ theme }) => `2px solid ${theme.colors.secondary}`};
    outline: 0;
    font-size: 20px;
    line-height: 1.3;
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;

const ButtonWrapper = styled(Flex)`
  justify-content: space-between;
`;

const Button = styled(BaseButton)`
  min-width: 100px;
`;

const TextWrapper = styled(Flex)`
  justify-content: space-between;
`;
