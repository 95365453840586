import { FC } from 'react';
import Footer from '../Footer';

const Main: FC = ({ children }) => (
  <>
    {children}
    <Footer />
  </>
);

export default Main;
