import { useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button as BaseButton, Title } from '../../components/Toolkit';

const Tutorial = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const backHandler = useCallback(() => {
    history.push('/demo/user/challenge');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="40px" textAlign="left">
          {t('tutorial title')}
        </Title>
        <Content>
          <Trans components={{ br: <br /> }}>usual</Trans>
        </Content>
        <ContentItalic>{t('note 3')}</ContentItalic>
        <Button type="button" onClick={backHandler}>
          {t('back challenges button')}
        </Button>
      </Container>
    </DemoWrapper>
  );
};

export default Tutorial;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const Button = styled(BaseButton)`
  margin-bottom: 15px;
`;

const Content = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #ffffff;
`;

const ContentItalic = styled(Content)`
  margin-bottom: 50px;
  font-style: italic;
`;
