import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button, Flex, Title } from '../../components/Toolkit';

const Container = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
`;

const Demo = () => {
  const history = useHistory();

  const changeHandler = useCallback(() => {
    history.push('/demo/step-2');
  }, [history]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="85px" textAlign="left" style={{ fontWeight: 400 }}>
          Welcome to e-Sovereignty’s Demo App. <br />
          <br />
          The Demo begins with the account setup, AFTER a user would have paid to access the service
          or to download the App.
        </Title>
        <Button onClick={changeHandler}>Start Demo Session</Button>
      </Container>
    </DemoWrapper>
  );
};

export default Demo;
