import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import DemoWrapper from '../../components/Demo';
import { Button, Input as BaseInput, SubTitle, Title } from '../../components/Toolkit';
import { fetchUserHandler } from '../../redux/actions';

const Secret = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.user);

  const [secretInfo, setSecretInfo] = useState('');

  const changeHandler = useCallback((e) => {
    setSecretInfo(e.target.value);
  }, []);

  const okHandler = useCallback(() => {
    const newUser = {
      ...user,
      secret: secretInfo,
    };
    dispatch(fetchUserHandler(newUser));
    history.push('/demo/user/complete');
  }, [dispatch, history, secretInfo, user]);

  return (
    <DemoWrapper>
      <Container>
        <Title mb="25px" textAlign="left">
          {t('user page')}
        </Title>
        <SubTitle textAlign="left">{t('step 3')}</SubTitle>
        <form>
          <Input value={secretInfo} required placeholder="..." onChange={changeHandler} />
          <Button type="button" onClick={okHandler}>
            {t('ok button')}
          </Button>
        </form>
      </Container>
    </DemoWrapper>
  );
};

export default Secret;

const Container = styled.div`
  max-width: 420px;
  min-height: calc(100vh - 60px);
  padding: 30px 25px;
  background: ${({ theme }) => theme.colors.primary};
  }
`;

const Input = styled(BaseInput)`
  margin: 25px 0;
`;
